<template>
  <nuxt :class="$param2TravelType($route.query.t)" />
</template>

<script>

export default {
  name: 'DefaultLayout'
}
</script>

<style lang="scss">
html,
body {
  @apply .bg-grey-light
         .text-black
         .p-1;

  /* stylelint-disable */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

@screen md {
  body {
    @apply p-3;
  }
}

h3, h4 {
  @apply font-medium;
}

.btn_text:hover {
  @apply underline;
}

.btn_icon {
  @apply align-middle
         flex;

  svg {
    @apply mr-1;
  }
}

.driv {
  h3, h4, .btn_text {
    @apply text-driv-primary
  }

  .bg-primary {
    @apply bg-driv-primary;
  }
}

.cars {
  h3, h4, .btn_text {
    @apply text-cars-primary
  }

  .bg-primary {
    @apply bg-cars-primary;
  }
}
</style>
