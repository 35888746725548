import moment from 'moment'
import { languages } from 'drive/constants/SupportedLanguages'

const defaultLocale = 'de'
const locales = []

languages.forEach((lang) => {
  locales.push(lang.locale)
})

moment.locale(defaultLocale)

const state = () => {
  return {
    locales,
    locale: defaultLocale
  }
}

const mutations = {
  SET_LANG(state, locale) {
    locale = state.locales.indexOf(locale) !== -1
      ? locale
      : defaultLocale

    moment.locale(locale)

    state.locale = locale
  }
}

const actions = {}

export default {
  state,
  mutations,
  actions
}
