export default ({ route }, inject) => {
  const param2TravelType = (parameter = '') => {
    const travelTypeMap = {
      d: 'driv',
      c: 'cars'
    }

    return travelTypeMap[parameter.toLowerCase()] || travelTypeMap.d
  }

  const param2Language = (parameter = '') => {
    const languageMap = {
      d: 'de',
      f: 'fr'
    }

    return languageMap[parameter.toLowerCase()] || languageMap.d
  }

  const param2Currency = (parameter = '') => {
    const currencyMap = {
      e: 'EUR',
      c: 'CHF'
    }

    return currencyMap[parameter.toLowerCase()] || currencyMap.e
  }

  inject('param2TravelType', param2TravelType)
  inject('param2Language', param2Language)
  inject('param2Currency', param2Currency)

  // add route param "lg" that auto-translation works with the lib translation plugin
  route.query.tt = param2TravelType(route.query.t)
  route.query.lg = param2Language(route.query.l)
  route.query.cur = param2Currency(route.query.c)
}
