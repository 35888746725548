import Vue from 'vue'
import Vuex from 'vuex'

import AppConfig from 'drive/store/AppConfig'
import Search from 'drive/store/Search'
import modules from './modules'

modules.Search = Search
modules.AppConfig = AppConfig
Vue.use(Vuex)

const createStore = () => {
  return new Vuex.Store({
    modules,
    strict: process.env.NODE_ENV !== 'production'
  })
}

export default createStore
