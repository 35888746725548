/* eslint-disable */
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default async ({ app, store, route }) => {
  const locale = (route.query.lg || '').toLowerCase()

  store.commit('SET_LANG', locale)

  try {
    const terms = await app.$driveAPI.getTranslations()

    app.i18n = new VueI18n({
      locale: store.state.Language.locale,
      fallbackLocale: 'xx',
      messages: {
        xx: require('~/locales/xx.json')
      },
      silentTranslationWarn: true,
    })

    const mergedTranslation = Object.assign({}, app.i18n.getLocaleMessage('xx'), terms)

    app.i18n.setLocaleMessage(store.state.Language.locale, mergedTranslation)
    app.i18n.locale = store.state.Language.locale
  } catch(error) {
    console.log(error)
  }
}
