import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _1b8a44d2 = () => interopDefault(import('../pages/o.vue' /* webpackChunkName: "pages/o" */))
const _1b1988ca = () => interopDefault(import('../pages/s.vue' /* webpackChunkName: "pages/s" */))
const _2429947a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

const scrollBehavior = function(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      }
      let position = {}
      if (to.matched.length < 2) {
        position = { x: 0, y: 0 }
      } else if (
        to.matched.some(r => r.components.default.options.scrollToTop)
      ) {
        position = { x: 0, y: 0 }
      }
      if (to.hash) {
        position = { selector: to.hash }
      }
      return position
    }

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/o",
      component: _1b8a44d2,
      name: "o"
    }, {
      path: "/s",
      component: _1b1988ca,
      name: "s"
    }, {
      path: "/",
      component: _2429947a,
      name: "index"
    }],

    fallback: false
  })
}
